<template>
  <v-row class="pt-2">
    <v-toolbar class="error">
      <v-toolbar-title>404-该功能尚未完成</v-toolbar-title>
    </v-toolbar>
  </v-row>
</template>

<script>
export default {}
</script>

<style></style>
